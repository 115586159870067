// src/NotFound.js
import React from 'react';
import BaseLayout from './BaseLayout';

const NotFound = () => {
    return (
        <BaseLayout>
            <main className="flex items-center justify-center h-screen ">
                <div className="text-center">
                    <h2 className="text-4xl font-bold">404 - Страница не найдена</h2>
                    <p className="mt-4 text-xl">Искомая страница не существует.</p>
                </div>
            </main>
        </BaseLayout>
    );
};

export default NotFound;
