// src/components/FavoriteButton.js
import React, { useContext, useState, useEffect } from 'react';
import FavoritesContext from '../contexts/FavoritesContext';
import { isFavorite } from '../utils/localStorage';

import FavIcon from '../assets/images/favorite_black.svg';
import FavFilledIcon from '../assets/images/favorite_filled.svg';

const FavoriteButton = ({ product }) => {
  const { favoritesCount, handleAddToFavorites, handleRemoveFromFavorites } = useContext(FavoritesContext);
  const [isFav, setIsFav] = useState(isFavorite(product.MoyskladID));

  useEffect(() => {
    setIsFav(isFavorite(product.MoyskladID));
  }, [favoritesCount, product.MoyskladID]);

  const handleClick = () => {
    if (isFav) {
      handleRemoveFromFavorites(product.MoyskladID);
    } else {
      handleAddToFavorites(product);
    }
    setIsFav(!isFav);
  };

  return (
    <button onClick={handleClick} className="w-[25px] h-[25px] transition duration-300 flex-shrink-0">
      {isFav ? (
        <img className="w-full h-full object-contain" src={FavFilledIcon} alt="Remove from Favorites" />
      ) : (
        <img className="w-full h-full object-contain" src={FavIcon} alt="Add from Favorites" />
      )}
    </button>
  );
};

export default FavoriteButton;
