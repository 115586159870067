// src/components/Checkout.js
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import CartContext from '../contexts/CartContext.js';
import SquareRadioButton from './SquareRadioButton.js';
import { useCity } from '../contexts/CityContext';


const Checkout = () => {
  const { cart, clearCart } = useContext(CartContext);
  const { selectedCity } = useCity();
  const totalProductSum = cart.reduce((sum, item) => sum + item.Price * item.quantity, 0);
  const discount = 0;
  const totalAfterDiscount = totalProductSum - discount;

  const [deliveryMethod, setDeliveryMethod] = useState('address');
  const [recipient, setRecipient] = useState('me');
  const [orderMessage, setOrderMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const contactDetails = {
    first_address: "Бизнес-центр Румянцево г. Москва, Киевское шоссе, 22 километр, 4, стр. 2, корп. В, подъезд 5",
    second_address: "Торговый центр МебельСити г. Саратов, ул. Симбирская, д. 154, 1 этаж",
  };

  const [formData, setFormData] = useState({
    full_name: '', telephone_number: '',
    email: '',
    comment: '',
    city: '',
    street: '',
    house: '',
    entrance: '',
    floor: '',
    apartment: '',
    another_full_name: '',
    another_telephone_number: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const orderData = {
      customer_info: { ...formData },
      total_amount: totalAfterDiscount,
      discount,
      items: cart.map(item => ({
        MoyskladID: item.MoyskladID,
        quantity: item.quantity,
        price: item.Price,
      }))
    };

    console.log('Submitting order data:', JSON.stringify(orderData, null, 2));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      if (response.ok) {
        clearCart();
        setOrderMessage('Заказ оформлен. В ближайшее время с вами свяжется наш менеджер.');
        setIsFormVisible(false);
        setFormData({
          full_name: '',
          telephone_number: '',
          email: '',
          comment: '',
          city: '',
          street: '',
          house: '',
          entrance: '',
          floor: '',
          apartment: '',
          another_full_name: '',
          another_telephone_number: '',
        });
      } else {
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <BaseLayout>
      <main className="font-montserrat-regular text-default padding-default">
        <div className="aside-default">
          <Link to="/">Главная / </Link>
          <Link to="/checkout">Оформление заказа</Link>
        </div>
        <h2 className="h2__aside mb-[28px] sm:mb-[50px]">Оформление заказа</h2>

        {isFormVisible ? (
          <form onSubmit={handleSubmit} className="mb-[30px] flex flex-col gap-5 text-default sm:flex-row ">
            <div className="flex flex-col gap-5 sm:w-[63%] ">
              {/* Delivery Method */}
              <div className="flex flex-col gap-3 border p-2
                              sm:pt-[40px] sm:pb-10 sm:px-5">
                <h3 className="h3__checkout xl:pb-[36px]">Способ доставки</h3>
                <div className="flex space-x-4 pb-[30px] sm:pb-[40px]">
                  <SquareRadioButton
                    label="Доставка до адреса"
                    value="address"
                    checked={deliveryMethod === 'address'}
                    onChange={() => setDeliveryMethod('address')}
                  />
                  <SquareRadioButton
                    label="Самовывоз"
                    value="pickup"
                    checked={deliveryMethod === 'pickup'}
                    onChange={() => setDeliveryMethod('pickup')}
                  />
                </div>

                {/* Conditional Rendering based on Delivery Method */}
                <div id="delivery-content">
                  {deliveryMethod === 'address' ? (
                    <div className="flex flex-col gap-[20px] sm:gap-2.5">
                      <div className="flex flex-col gap-[20px]
                                      sm:gap-2 sm:h-[40px] sm:mb-[20px] sm:flex-row sm:justify-between">
                        <input
                          className="w-full pl-[16px] placeholder:text-black border-default"
                          type="text"
                          name="city"
                          placeholder="Город"
                          value={formData.city}
                          onChange={handleChange}
                          required
                        />
                        <input
                          className="w-full pl-[16px] placeholder:text-black border-default "
                          type="text"
                          name="street"
                          placeholder="Улица"
                          value={formData.street}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="flex flex-col gap-[20px] mb-[20px]
                                      sm:gap-2 sm:h-[40px] sm:flex-row sm:justify-between">
                        <input
                          className="sm:w-[25%] indent-[16px] placeholder:text-black border-default"
                          type="text"
                          name="house"
                          placeholder="Дом"
                          value={formData.house}
                          onChange={handleChange}
                          required
                        />
                        <input
                          className="sm:w-[25%] indent-[16px] placeholder:text-black border-default"
                          type="text"
                          name="entrance"
                          placeholder="Подъезд"
                          value={formData.entrance}
                          onChange={handleChange}
                        />
                        <input
                          className="sm:w-[25%] indent-[16px] placeholder:text-black border-default"
                          type="number"
                          name="floor"
                          placeholder="Этаж"
                          value={formData.floor}
                          onChange={handleChange}
                        />
                        <input
                          className="sm:w-[25%] indent-[16px] placeholder:text-black border-default"
                          type="number"
                          name="apartment"
                          placeholder="Квартира"
                          value={formData.apartment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col xl:mb-[30px] xl:h-[110px]">
                        <p className="border p-[16px] sm:p-[20px]">
                        {selectedCity === 'moscow' ? contactDetails.first_address : contactDetails.second_address}
                        </p>
                    </div>
                  )}
                </div>
                <div className="text-[10px] sm:text-[12px] xl:text-[18px]">Точная стоимость и сроки доставки рассчитываются продавцом при подтверждении заказа.</div>
              </div>

              {/* Contact Details */}
              <div className="flex flex-col gap-3 border p-2
                              sm:pt-[40px] sm:pb-10 sm:px-5">
                <h3 className="h3__checkout xl:pb-[36px]">Контактная информация</h3>
                <div className="flex flex-col gap-[20px]">
                  <input
                    className="indent-[16px] placeholder:text-black border-default
                              sm:w-[50%] sm:h-[40px]"
                    type="text"
                    name="full_name"
                    placeholder="Имя"
                    value={formData.full_name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="indent-[16px] placeholder:text-black border-default
                              sm:h-[40px] sm:w-[50%]"
                    type="text"
                    name="telephone_number"
                    placeholder="Телефон"
                    value={formData.telephone_number}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="indent-[16px] placeholder:text-black border-default
                              sm:h-[40px] sm:w-[50%]"
                    type="email"
                    name="email"
                    placeholder="E-Mail"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    className="pl-[16px] placeholder:text-black border-default
                              h-[40px] sm:h-[80px] sm:w-[50%]"
                    type="text"
                    name="comment"
                    placeholder="Комментарии к заказу"
                    value={formData.comment}
                    onChange={handleChange}
                  />
                </div>
              </div>
            {/* Recipient */}
                  <div className="flex flex-col gap-3 border p-2 sm:pt-[40px] sm:pb-10 sm:px-5">
                    <h3 className="h3__checkout xl:pb-[36px]">Получатель</h3>
                    <div className="flex space-x-4 pb-[20px] sm:pb-[40px]">
                    <SquareRadioButton
                      label="Я"
                      value="me"
                      checked={recipient === 'me'}
                      onChange={() => setRecipient('me')}
                    />
                    <SquareRadioButton
                      label="Другой человек"
                      value="other"
                      checked={recipient === 'other'}
                      onChange={() => setRecipient('other')}
                    />
                    </div>
                    {/* Conditional Rendering for Recipient */}
                    <div id="recipient-content">
                      {recipient === 'other' && (
                        <div className="flex flex-col gap-[20px]">
                          <input
                            className="indent-[16px] placeholder:text-black border-default sm:w-[50%] sm:h-[40px]"
                            type="text"
                            name="another_full_name"
                            placeholder="Имя"
                            value={formData.another_full_name}
                            onChange={handleChange}
                          />
                          <input
                            className="indent-[16px] placeholder:text-black border-default sm:w-[50%] sm:h-[40px]"
                            type="text"
                            name="another_telephone_number"
                            placeholder="Телефон"
                            value={formData.another_telephone_number}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
            </div>
            {/* Right Container / bottom */}
            <div className="border flex flex-col justify-between sm:gap-2 h-[70%]
                            sm:w-[34%]">
              <div className="flex flex-col gap-[24px] py-[30px] px-[20px] sm:pt-[40px] sm:pb-[30px]">
                <div className="flex flex-row">
                  <span className="font-montserrat-medium flex-1
                                  text-[12px] sm:text-[12px] xl:text-[18px]">Товары</span>
                  <span className="text-[12px] sm:text-[12px] xl:text-[18px]">{totalProductSum ? totalProductSum : '0'} Р</span>
                </div>
                <div className="flex flex-row">
                  <span className="font-montserrat-medium flex-1
                                  text-[12px] sm:text-[12px] xl:text-[18px]">
                                    Скидка
                  </span>
                  <span className="text-[12px] sm:text-[12px] xl:text-[18px]">{discount ? discount.toFixed(2) : '0'} Р</span>
                </div>
              </div>
              <div className="flex flex-col gap-[24px] border-black border-solid px-[20px] 
                              sm:border-t-[1px] pb-[20px] sm:py-[40px]">
                <div className="flex flex-row">
                  <span className="font-montserrat-medium flex-1
                                  text-[12px] sm:text-[12px] md:text-[18px] xl:text-[24px]">Итого</span>
                  <span className="text-[12px] sm:text-[12px] md:text-[18px] xl:text-[24px]">{totalAfterDiscount ? totalAfterDiscount : '0'} Р</span>
                </div>
                <button type="submit" className="text-[12px] sm:text-[14px] md:text-[16px] xl:text-[18px] bttn">Оформить заказ</button>
                <p className="text-[12px] md:text-[16px] xl:text-[16px]">Нажимая «Оформить заказ» вы соглашаетесь с договором оферты и подтверждаете своё согласие на обработку персональных данных</p>
              </div>
            </div>
          </form>
        ) : (
          <div className="pb-[30px] sm:pb-[40px] order-message">{orderMessage}</div>
        )}
      </main>
    </BaseLayout>
  );
};

export default Checkout;
