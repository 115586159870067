// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CityProvider } from './contexts/CityContext'; 
import Home from './components/Home';
import About from './components/About';
import Catalog from './components/Catalog';
import Tables from './components/Tables';
import Chairs from './components/Chairs';
import Sofas from './components/Sofas';
import Armchairs from './components/Armchairs';
import CoffeeTables from './components/CoffeeTables';
import Furnishings from './components/Furnishings';
import DeliveryPayment from './components/DeliveryPayment';
import Designers from './components/Designers';
import Contacts from './components/Contacts';
import Favorite from './components/Favorite';
import CartSummary from './components/CartSummary';
import Checkout from './components/Checkout';
import Privacy from './components/Privacy';
import Offer from './components/Offer.js';
import ExtendedProductCard from './components/ExtendedProductCard';
import SearchResults from './components/SearchResults';
import NotFound from './components/NotFound.js';


const App = () => {
  return (
    <CityProvider>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/delivery_payment" element={<DeliveryPayment />} />
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/catalog/tables" element={<Tables />} />
      <Route path="/catalog/chairs" element={<Chairs />} />
      <Route path="/catalog/sofas" element={<Sofas />} />
      <Route path="/catalog/armchairs" element={<Armchairs />} />
      <Route path="/catalog/coffee_tables" element={<CoffeeTables />} />
      <Route path="/catalog/furnishings" element={<Furnishings />} />
      <Route path="/catalog/:category/:moysklad_id" element={<ExtendedProductCard />} />
      <Route path="/designers" element={<Designers />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/favorite" element={<Favorite />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/cart" element={<CartSummary />} />
      <Route path="/search" element={<SearchResults />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/offer" element={<Offer />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </CityProvider>
  );
};

export default App;
