import React, { createContext, useState, useEffect } from 'react';
import { saveCart, loadCart } from '../utils/localStorage'; // Import from localStorage utility

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load the cart from localStorage when the component mounts
    return loadCart() || [];
  });

  useEffect(() => {
    // Save the cart to localStorage whenever it changes
    saveCart(cart);
  }, [cart]);

  // Add product to the cart or update its quantity
  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.MoyskladID === product.MoyskladID);

      if (existingProduct) {
        // If product is already in the cart, increment its quantity
        return prevCart.map((item) =>
          item.MoyskladID === product.MoyskladID
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        // If product is not in the cart, add it with an initial quantity of 1
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  // Remove product from the cart
  const removeFromCart = (MoyskladID) => {
    setCart((prevCart) => prevCart.filter((item) => item.MoyskladID !== MoyskladID));
  };

  const clearCart = () => {
    setCart([]); // Set the cart to an empty array
  };

  // Update the quantity of a specific product in the cart
  const updateCartItemQuantity = (MoyskladID, quantity) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.MoyskladID === MoyskladID ? { ...item, quantity } : item
        )
        .filter((item) => item.quantity > 0) // Remove item if quantity is 0
    );
  };

  // Get the total number of items in the cart (sum of all quantities)
  const getCartCount = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, updateCartItemQuantity, getCartCount }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
