import React from 'react';
import { Link } from 'react-router-dom';

const Nav = ({ items }) => {
  return (
        <nav className="navigation">
          <ul className="flex flex-row">
            {Object.entries(items).map(([url, name], index) => (
              <li key={url}>
                {index < Object.entries(items).length - 1 ? (
                  <Link to={`/${url}`} className="">
                    {name} /&nbsp;   
                  </Link>
                  ) : (
                  <span className="">{name}</span> // Current page, no link
                  )}
              </li>
              ))}
          </ul>
        </nav>
  )
}

export default Nav;
