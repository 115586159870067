// components/BaseLayout.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // If using react-router for navigation
import axios from 'axios';
import Header from './Header';
import Footer from './Footer.js';
import FavoritesContext from '../contexts/FavoritesContext';
import CartContext from '../contexts/CartContext';
import { useCity } from '../contexts/CityContext';

const BaseLayout = ({ children }) => {
    const { favoritesCount } = useContext(FavoritesContext);
    const { getCartCount } = useContext(CartContext); // Access cart count
    const cartCount = getCartCount(); // Get the cart count

    const [products, setProducts] = useState([]); // Store all products
    const [searchTerm, setSearchTerm] = useState(''); // Store user search input
    const { selectedCity, handleCityChange } = useCity();
    // const [selectedCity, setSelectedCity] = useState('moscow'); // Default city

    const navigate = useNavigate(); // Hook to navigate between pages
    const [isSearchOpen, setIsSearchOpen] = useState(false);



  // Fetch products from API
  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/products/`,
  //     {params: {city: selectedCity}})
  //     .then((response) => {
  //       setProducts(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching products:', error);
  //     });
  // }, [selectedCity]);

  // Handle search submission (navigate to search results page)
  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting traditionally
    navigate(`/search?query=${searchTerm}`); // Navigate to the search results page
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close the menu if clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Check if the click is outside of the menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false); // Close the menu
            }
        };

        // Add event listener to detect outside clicks
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        // Cleanup event listener on unmount or when the menu closes
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isMenuOpen]);

  return (
    <>
      {/* Header */}
      <Header />

        {children}

      {/* Footer */}
      <Footer/>
    </>
  );
};

export default BaseLayout;
