// src/contexts/FavoritesContext.js
import React, { createContext, useState } from 'react';
import { loadFavorites, saveFavorites } from '../utils/localStorage';

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(loadFavorites() || []);

  const handleAddToFavorites = (product) => {
    const alreadyFavorite = favorites.some(fav => fav.MoyskladID === product.MoyskladID);
    if (!alreadyFavorite) {
      const updatedFavorites = [...favorites, product];
      setFavorites(updatedFavorites);
      saveFavorites(updatedFavorites);
    }
  };

  const handleRemoveFromFavorites = (MoyskladID) => {
    const updatedFavorites = favorites.filter(fav => fav.MoyskladID !== MoyskladID);
    setFavorites(updatedFavorites);
    saveFavorites(updatedFavorites);
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        favoritesCount: favorites.length,
        handleAddToFavorites,
        handleRemoveFromFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesContext;
// // src/contexts/FavoritesContext.js
// import React, { createContext, useState, useEffect } from 'react';
//
// // Helper functions for localStorage (optional)
// import { loadFavorites, saveFavorites } from '../utils/localStorage';
//
// const FavoritesContext = createContext();
//
// export const FavoritesProvider = ({ children }) => {
//   const [favorites, setFavorites] = useState(loadFavorites() || []);
//
//   // Handle add to favorites
//   const handleAddToFavorites = (product) => {
//     const updatedFavorites = [...favorites, { ...product, id: product.moysklad_id }];
//     setFavorites(updatedFavorites);
//     saveFavorites(updatedFavorites); // Save to localStorage
//   };
//
//   // Handle remove from favorites
//   const handleRemoveFromFavorites = (moyskladId) => {
//     const updatedFavorites = favorites.filter((fav) => fav.moysklad_id !== moyskladId);
//     setFavorites(updatedFavorites);
//     saveFavorites(updatedFavorites); // Save to localStorage
//   };
//
//   return (
//     <FavoritesContext.Provider
//       value={{
//         favorites,
//         favoritesCount: favorites.length,
//         handleAddToFavorites,
//         handleRemoveFromFavorites,
//       }}
//     >
//       {children}
//     </FavoritesContext.Provider>
//   );
// };
//
// export default FavoritesContext;
