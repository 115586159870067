// components/Home.js
import React, { useState, useEffect, } from 'react';
import { Link } from 'react-router-dom'; // If using react-router for navigation
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Carousel from './Carousel';
import MapComponent from './Map';
import sideSecond from '../assets/images/side_second.jpg';
import sideBack from '../assets/images/side_back.png';
import guaranteeIcon from '../assets/images/guarantee.svg';
import priceIcon from '../assets/images/guarantee.svg';
import shoppingIcon from '../assets/images/shopping-list.svg';
import guaranteeSecondIcon from '../assets/images/guarantee-second.svg';
import telegramBlackIcon from '../assets/images/telegram_black.svg';
import instaBlackIcon from '../assets/images/insta_black.svg';
import whatsupBlackIcon from '../assets/images/whatsup_black.svg';
import { useCity } from '../contexts/CityContext';



const Home = ({ children }) => {
    // const navigate = useNavigate(); 
    const { selectedCity, handleCityChange  } = useCity();

    // state variables for each product category
    const [tables, setTables] = useState([]);
    const [chairs, setChairs] = useState([]);
    const [armchairs, setArmchairs] = useState([]);




  // Fetch products from API
  useEffect(() => {
        const fetchProducts = async () => {
            try {
                const tablesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/3`, {params: {city: selectedCity}});
                const chairsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/1`, {params: {city: selectedCity}});
                const armchairsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/4`, {params: {city: selectedCity}});

                setTables(tablesResponse.data);
                setChairs(chairsResponse.data);
                setArmchairs(armchairsResponse.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
  }, []);


  return (
    <>
    <Header />

	<main className="font-montserrat-medium text-default padding-default">
	  <section>
		    {/* section header */}
        <div className="flex flex-row justify-between items-center pt-[42px] pb-[30px] sm:py-[50px]">
            <h2 className="h2__home">Столы</h2>
            <Link className="a-home" to="/catalog/tables">Смотреть все ></Link>
        </div>
		    {/* carousel */}
        <Carousel products={tables} category="tables" />
    </section>

	  <section>
		    {/* section header */}
        <div className="flex flex-row justify-between items-center pt-[42px] pb-[30px] sm:py-[50px]">
            <h2 className="h2__home">Стулья</h2>
            <Link className="a-home" to="/catalog/chairs">Смотреть все ></Link>
        </div>
		    {/* carousel */}
        <Carousel products={chairs} category="chairs" />
    </section>

	  <section>
		    {/* section header */}
        <div className="flex flex-row justify-between items-center pt-[42px] pb-[30px] sm:py-[50px]">
            <h2 className="h2__home">Кресла</h2>
            <Link className="a-home" to="/catalog/armchairs">Смотреть все ></Link>
        </div>
		    {/* carousel */}
        <Carousel products={armchairs} category="armchairs" />
    </section>
    <section className="flex justify-center my-[30px] sm:my-[40px]">
      <Link className="bttn font-montserrat-medium py-[12px] px-[20px] sm:px-[26px]" to="/catalog">Перейти в каталог</Link>
    </section>
    {/* about mobile */}
    <section className="font-montserrat-regular block sm:hidden">
                    <p className="text-center w-auto sm:w-[360px]"> 
                      Мебельная компания “Том” специализируется на продаже мебели высокого качества в Саратове и Москве. Мы гордимся своей репутацией надежного поставщика стильной и функциональной мебели, которая удовлетворяет самым высоким стандартам.
                    </p>
                    <div className="flex flex-col justify-center">
                        <div className="flex flex-row justify-center">
                            <div className="sm:w-[345px] flex flex-col items-center gap-[14px] py-[20px] sm:pt-[46px] sm:pb-[20px]">
                                        <img className="w-[35px] h-[35px]" src={guaranteeSecondIcon} />
                                        <h3 className="a-home sm:my-[16px]">Высокое качество</h3>
                                        <p className="text-center sm:w-[240px]">Этот текст о высоком качестве товара</p>
                            </div>
                            <div className="sm:w-[345px] flex flex-col items-center gap-[14px] py-[20px] sm:pt-[46px] sm:pb-[20px]">
                                <img className="w-[35px] h-[35px]" src={priceIcon} />
                                <h3 className="a-home sm:my-[16px]">Приятные цены</h3>
                                <p className="text-center sm:w-[240px]">Этот текст о приятных невысоких ценах</p>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between">
                                    <div className="sm:w-[345px] flex flex-col gap-[14px] items-center pt-[20px]">
                                        <img className="w-[35px] h-[35px]" src={guaranteeIcon} />
                                        <h3 className="a-home sm:my-[16px]">Гарантия</h3>
                                        <p className="text-center sm:w-[240px]">Этот текст о высоком качестве товара</p>
                                    </div>
                                    <div className="sm:w-[345px] flex flex-col gap-[14px] items-center pt-[20px]">
                                        <img className="w-[35px] h-[35px]" src={shoppingIcon} />
                                        <h3 className="a-home sm:my-[16px]">Широкий выбор</h3>
                                        <p className="text-center sm:w-[240px]">Этот текст о широком выборе товаров</p>
                                    </div>
                        </div>
                    </div>
    </section>
		{/* contacts */}
	  <section className="sm:mb-[70px]">
		    {/* section header */}
        <div className="flex flex-row justify-between items-center pt-[42px] pb-[30px] sm:pb-[20px] sm:pt-[40px]">
            <h2 className="h2__home">Как нас найти</h2>
        </div>
      <MapComponent />
    </section>
  </main>
      {/* Footer */}
    <Footer />
  </>
  );
}

export default Home;
