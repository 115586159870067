//
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import CartContext from '../contexts/CartContext';
import SquareCheckbox from './SquareCheckBox.js';
import Nav from './Nav.js';

const CartSummary = () => {
  const { cart, addToCart, removeFromCart, updateCartItemQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const navigationItems = {
    '': 'Главная',
    'checkout': 'Корзина',
  };

  // Initialize selectedItems state with all items set to true (selected)
  const [selectedItems, setSelectedItems] = useState(
    cart.reduce((acc, item) => {
      acc[item.MoyskladID] = true;
      return acc;
    }, {})
  );

  // Handle checkbox changes (without deleting the item)
  const handleItemCheckChange = (itemId, isChecked) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [itemId]: isChecked,
    }));
  };

  // Calculate the total price of selected products
  const totalProductSum = cart.reduce((sum, item) => {
    return selectedItems[item.MoyskladID] ? sum + item.Price * item.quantity : sum;
  }, 0);

  const discount = 0;
  const totalAfterDiscount = totalProductSum - discount;

  // Handle checkout button click: Remove unchecked items and navigate to the checkout page
  const handleCheckout = () => {
    const itemsToRemove = cart.filter((item) => !selectedItems[item.MoyskladID]);
    itemsToRemove.forEach((item) => {
      removeFromCart(item.MoyskladID);
    });

    // Navigate to the checkout page with the selected items
    navigate('/checkout', {
      state: { totalProductSum, discount, totalAfterDiscount },
    });
  };

  const handleIncreaseQuantity = (item) => {
    updateCartItemQuantity(item.MoyskladID, item.quantity + 1);
  };

  const handleDecreaseQuantity = (item) => {
    if (item.quantity > 1) {
      updateCartItemQuantity(item.MoyskladID, item.quantity - 1);
    } else {
      removeFromCart(item.MoyskladID);
    }
  };

  return (
    <BaseLayout>
      <main className="font-montserrat-regular px-5 sm:pb-11">
        {cart.length === 0 ? (
          <>
            <Nav items={navigationItems} />
            <h2 className="h2__aside pb-10 sm:pb-11">Корзина</h2>
            <p>Корзина пуста</p>
          </>
        ) : (
          <>
            <Nav items={navigationItems} />
            <h2 className="h2__aside pb-10 sm:pb-11">Корзина</h2>

            <div className="flex flex-col sm:flex-row gap-5 text-default">
              {/* Cart Items Section */}
              <div className="flex flex-col w-full gap-3">
                {cart.map((item) => (
                  <div
                    key={item.MoyskladID}
                    className="flex flex-row items-center justify-between border px-4 py-6"
                  >
                    <SquareCheckbox
                      value={item.MoyskladID}
                      checked={selectedItems[item.MoyskladID] || false}
                      onChange={(e) => handleItemCheckChange(item.MoyskladID, e.target.checked)}
                    />

                    <img
                      className="w-[200px] h-[100px] object-contain"
                      src={`${process.env.REACT_APP_API_URL}/${item.ProductImages[0]?.ImageURL}`}
                      alt={item.Name}
                    />

                    <div className="flex flex-col gap-4 md:flex-row items-center w-[56%]">
                      <div>
                        <p className="font-montserrat-medium text-[16px]">{item.Name}</p>
                        <button
                          className="text-slate-700 hover:underline"
                          onClick={() => removeFromCart(item.MoyskladID)}
                        >
                          Удалить
                        </button>
                      </div>

                      <div>{item.quantity * item.Price} ₽</div>

                      <div className="flex items-center border rounded px-3">
                        <button onClick={() => handleDecreaseQuantity(item)}>-</button>
                        <input type="text" value={item.quantity} readOnly className="w-12 text-center" />
                        <button onClick={() => handleIncreaseQuantity(item)}>+</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Summary Section */}
              <div className="border flex flex-col gap-6 p-6 sm:w-[28%]">
                <button onClick={handleCheckout} className="w-full sm:text-[18px] bttn">
                  Перейти к оформлению
                </button>
                <p className="text-justify">
                  Точная стоимость и сроки доставки рассчитываются продавцом при подтверждении заказа.
                </p>
                <div className="border-t pt-6">
                  <div className="flex justify-between">
                    <span>Товары</span>
                    <span>{totalProductSum} Р</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Скидка</span>
                    <span>{discount} Р</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Итого</span>
                    <span>{totalAfterDiscount} Р</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </BaseLayout>
  );
};

export default CartSummary;
