import React, { useState } from 'react';
import axios from 'axios';
import popupImage from '../assets/images/popup.jpg'; // Adjust path as needed
import InputMask from 'react-input-mask';

const PopupComponent = ({ buttonText, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    telephone: '',
    city: ''
  });
  const [message, setMessage] = useState('');

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send data to the backend
    axios.post(`${process.env.REACT_APP_API_URL}/api/feedback`, formData)
      .then((response) => {
        setMessage('Спасибо за обратную связь!');
        setFormData({ name: '', telephone: '', city: '' }); // Clear the form
        togglePopup(); // Close the popup if needed
      })
      .catch((error) => {
        setMessage('Ошибка при отправке данных');
        console.error('Error submitting feedback:', error);
      });
  };

  return (
    <div>
      <button onClick={togglePopup} className={className}>
        {buttonText}
      </button>

      {isOpen && (
        <div className="mx-[50px] fixed text-black inset-0 flex items-center justify-center z-50">
          {/* Background overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-50" onClick={togglePopup}></div>

          {/* Popup content */}
          <div className="sm:flex sm:flex-col popup__content bg-white rounded-lg shadow-lg relative sm:max-h-[80vh]">
            <div className="flex flex-row justify-between p-[8px] sm:p-[15px]">
              <h2 className="text-[14px] xl:text-[18px] font-montserrat-semibold">Заказать обратный звонок</h2>
              <button className="sm:text-[18px] text-[14px]" onClick={togglePopup}>X</button>
            </div>

            <form className="sm:flex-grow flex flex-col w-auto sm:flex-row " onSubmit={handleSubmit}>
       
                {/* Left image */}
                <div className="sm:w-[50%]">
                  <img className="w-full h-full object-cover bg-bottom" src={popupImage} alt="Popup" />
                </div>

                {/* Right form section */}
                <div className="sm:w-[50%]">
                  <div className="text-justify flex flex-col gap-5 py-[40px] px-[20px] border-black border-[0.5px] border-solid border-x-0
                                  sm:gap-[20px] sm:py-[65px] sm:pl-[30px] sm:pr-[110px]">
                    <p className="text-left text-wrap text-[14px] md:text-[18px]">
                      Введите Ваши имя и номер телефона.<br/>
                      Специалист компании свяжется с Вами и ответит на все интересующие вопросы.
                    </p>
                    <input
                      className="indent-[10px] inpt h-[30px]"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Имя"
                      required
                    />
                    <InputMask
                      mask="+9-999-999-99-99"
                      value={formData.telephone}
                      onChange={handleChange}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          className="indent-[10px] inpt h-[30px]"
                          type="text"
                          name="telephone"
                          placeholder="Телефон"
                          required
                        />
                      )}
                    </InputMask>
                    <input
                      className="indent-[10px] inpt h-[30px] "
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="Город"
                    />
                    <button className="bttn text-[12px] outline-0" type="submit">
                      Отправить
                    </button>
                  </div>
                  <div className="text-balance text-left text-[12px] py-[20px] px-[20px]">
                    Или позвоните нам по номеру: <a href="tel:+79850905417">+7 (985) 090-54-17</a>
                  </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default PopupComponent;
