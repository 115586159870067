// components/SearchResults.js
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { useCity } from '../contexts/CityContext';
import BaseLayout from './BaseLayout';
import ProductCard from './ProductCard';

const SearchResults = () => {
  const { selectedCity } = useCity();
  const [products, setProducts] = useState([]);
  const location = useLocation();

  const categoryMapping = {
    1: 'chairs',
    3: 'tables',
    4: 'furnishings',
    5: 'armchairs',
    6: 'coffee_tables',
    7: 'sofas',
  };

  // Get the search term from the URL
  const searchTerm = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products`, {
          params: { city: selectedCity, query: searchTerm },
        });
        setProducts(response.data || []);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (searchTerm) {
      fetchProducts();
    }
  }, [searchTerm, selectedCity]);

  return (
    <BaseLayout>
      <main className="padding-default pb-5">
        <aside className="aside-default">
          <Link to="/">Главная / </Link>
          <Link to="/search">Поиск</Link>
        </aside>
        <h2 className="h-default">Результаты поиска: "{searchTerm}"</h2>
        <div className="flex flex-col gap-5 sm:flex-row flex-wrap">
          {products.length > 0 ? (
            products.map((product) => {
              const categoryName = categoryMapping[product.CategoryID] || 'default';

              return (
                <ProductCard key={product.MoyskladID}
                           selectedCity={selectedCity}
                           product={product}
                           category={categoryName} />
              );
            })
          ) : (
            <p>Продукты не найдены.</p>
          )}
        </div>
      </main>
    </BaseLayout>
  );
};

export default SearchResults;
