// src/components/About.js
import React from 'react';
import BaseLayout from './BaseLayout';
import sideSecond from '../assets/images/side_second.jpg';
import guaranteeIcon from '../assets/images/guarantee.svg';
import priceIcon from '../assets/images/guarantee.svg';
import shoppingIcon from '../assets/images/shopping-list.svg';
import guaranteeSecondIcon from '../assets/images/guarantee-second.svg';
import Nav from './Nav.js';


const About = () => {
    const navigationItems = {
        '': 'Главная', 
        'about': 'О нас', 
    };

  return (
    <BaseLayout>
     <main className="font-montserrat-regular text-default padding-default">
        {/* aside nav */}
      <Nav items={navigationItems} />
        {/* h for pages */}
        <h2 className="h-default pb-[26px] sm:pb-[32px]">
          История компании
        </h2>
        <div className="font-montserrat-regular text-[14px] sm:text-[16px] pb-[40px] sm:pb-[60px]">
            <p className="pb-[30px] sm:pb-[40px]">Через тернии к звездам.</p>
            <p className="pb-[30px]">
                История компании "Том" началась много лет назад, когда ее основатель решил открыть свой первый мебельный магазин в маленьком городке.
                Его страсть к красивой, качественной и функциональной мебели вдохновила его на создание уютных и стильных интерьерных решений для дома.
            </p>
            <p className="pb-[30px]">
                С течением времени магазин "Том" стал пользоваться популярностью благодаря не только широкому выбору мебели,
                но и отличному сервису и вниманию к потребностям каждого клиента.
                Постепенно сеть магазинов "Том" расширилась, предлагая широкий ассортимент столов, стульев и диванов для любого вкуса и бюджета.
            </p>
            <p className="pb-[30px]">
                Сегодня компания "Том" является одним из лидеров в области продажи мебели, известной своим индивидуальным подходом к каждому клиенту
                и постоянным стремлением к совершенствованию. Магазины "Том" стали местом, где можно найти идеальную мебель для создания уютного и стильного интерьера своего дома.
            </p>
        </div>
        <div className="
                    rounded border-0 border-solid color-footer flex flex-col bg-right-bottom
                    sm:bg-none sm:flex-row ">
            <div className="
                        flex-1 text-14 bg-back
                        md:text-16
                        lg:text-18 
                        xl:text-18 sm:pb-[130px]">
                {/* h for pages */}
                <h2 className="h-default py-[26px] sm:pt-[84px] sm:pb-[30px]">
                  Наши преимущества
                </h2>
                <div className="">
                    <p className="w-auto sm:w-[360px]">Помогаем вам воплотить в жизнь самые смелые интерьерные задумки</p>
                    <div className="flex flex-col justify-between">
                        <div className="flex flex-col justify-between sm:justify-normal sm:flex-row">
                            <div className="sm:w-[345px] flex flex-col justify-between py-[20px] sm:pt-[46px] sm:pb-[20px]">
                                        <img className="w-[35px] h-[35px]" src={guaranteeSecondIcon} alt="guarantee"/>
                                        <h3 className="a-home sm:my-[16px]">Высокое качество</h3>
                                        <p className="sm:w-[240px]">Этот текст о высоком качестве товара</p>
                            </div>
                            <div className="sm:w-[345px] flex flex-col justify-between py-[20px] sm:pt-[46px] sm:pb-[20px]">
                                <img className="w-[35px] h-[35px]" src={priceIcon} alt="price"/>
                                <h3 className="a-home sm:my-[16px]">Приятные цены</h3>
                                <p className="sm:w-[240px]">Этот текст о приятных невысоких ценах</p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between pb-[20px] sm:p-0 sm:justify-normal sm:flex-row">
                                    <div className="sm:w-[345px] flex flex-col justify-between pt-[20px]">
                                        <img className="w-[35px] h-[35px]" src={guaranteeIcon} alt="quantity"/>
                                        <h3 className="a-home sm:my-[16px]">Гарантия</h3>
                                        <p className="sm:w-[240px]">Этот текст о высоком качестве товара</p>
                                    </div>
                                    <div className="sm:w-[345px] flex flex-col justify-between pt-[20px]">
                                        <img className="w-[35px] h-[35px]" src={shoppingIcon} alt=""/>
                                        <h3 className="a-home sm:my-[16px]">Широкий выбор</h3>
                                        <p className="sm:w-[240px]">Этот текст о широком выборе товаров</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="hidden flex-1 p-0 sm:flex sm:w-[710px] sm:h-[400px] md:h-[500px] lg:h-[600px] xl:h-[650px]"
                         src={sideSecond}
                         alt="Description" />
                </div>
            </main>
    </BaseLayout>
  );
};

export default About;
