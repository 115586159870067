// src/components/Carousel.js
import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import LeftArrow from '../assets/images/left-arrow.svg';
import RightArrow from '../assets/images/right-arrow.svg';
import { useCity } from '../contexts/CityContext';

const Carousel = ({ products, category }) => {
  const { selectedCity } = useCity();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

useEffect(() => {
    // Function to update itemsPerPage based on window size
    const updateItemsPerPage = () => {
      if (window.innerWidth >= 1444) { // Large screens
        setItemsPerPage(4);
      } else if (window.innerWidth >= 1024) { // Large screens
        setItemsPerPage(3);
      } else if (window.innerWidth >= 768) { // Medium screens
        setItemsPerPage(2);
      } else { // Small screens
        setItemsPerPage(2);
      }
    };

    // Initial check
    updateItemsPerPage();

    // Add resize event listener
    window.addEventListener('resize', updateItemsPerPage);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  // Function to go to the next slide
  const handleNext = () => {
    if (currentIndex + itemsPerPage < products.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  // Function to go to the previous slide
  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  // Calculate which products to show on the current slide
  const visibleProducts = products.slice(currentIndex, currentIndex + itemsPerPage);


  return (
    <>

    <div className="flex flex-row justify-between w-full max-w-full ">

      {/* Left Arrow */}
      <button 
        className="mr-[8px] sm:mr-[30px] transform transition duration-200 ease-in-out"
        onClick={handlePrev}
        disabled={currentIndex === 0}>
          <img src={LeftArrow} alt="RightArrow"
              className="carusel__arrow" />
      </button>
      {/* Carousel Content */}
      <div className="flex flex-row gap-[8px] sm:gap-[12px] md:gap-[20px] lg:gap-[30px] justify-center overflow-hidden">
        {visibleProducts.map((product, index) => (
          <div key={index} className="">
            <ProductCard product={product} selectedCity={selectedCity} category={category} />
          </div>
        ))}
      </div>
      {/* Right Arrow */}
      <button 
        className="ml-[8px] sm:ml-[30px] transform transition duration-200 ease-in-out"
        onClick={handleNext}
        disabled={currentIndex + itemsPerPage >= products.length} >
          <img src={RightArrow} alt="RightArrow"
              className="carusel__arrow" />
      </button>

    </div>
    </>
  );
};

export default Carousel;

