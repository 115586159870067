// StyleFilter.js
import React from 'react';

function StyleFilter({ styles, selectedStyles, onStyleChange }) {
  return (
    <div className="style-filter">
      {styles.map((style) => (
        <button
          key={style}
          className={`style-filter__bttn ${selectedStyles.includes(style) ? 'style-filter__bttn-clicked' : ''}`}
          onClick={() => onStyleChange(style)}
        >
          {style}
        </button>
      ))}
    </div>
  );
}

export default StyleFilter;
