// src/components/ProductCard.js
import React from 'react';
import FavoriteButton from './FavoriteButton';
import { Link } from 'react-router-dom';


function ProductCard({ product, category, selectedCity }) {
  // if (product.price === 0) return null;
  const imageURL = product.ProductImages
    ? `${process.env.REACT_APP_API_URL}/${product.ProductImages[0]?.ImageURL}`
    : null;

//  const imageURL = `${process.env.REACT_APP_API_URL}/${product.ProductImages[0]?.ImageURL}`; 

  return (
    <div className="card min-h-[300px] w-[180px] sm:min-w-[350px] sm:h-auto border-default">
      {/* card header */}
      <div className="flex flex-row justify-between">
        <Link 
          to={{
            pathname: `/catalog/${category}/${product.MoyskladID}`, 
            search: `?city=${selectedCity}`
          }}
          state={{ product }} // Pass the product data as state
          className="flex-grow text-center overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {product.Name}
        </Link>
        <FavoriteButton className="" product={product} /> {/* Pass full product object */}
      </div>
      <div className="flex flex-col items-center">
      {/* card image */}
        <Link to={{
            pathname: `/catalog/${category}/${product.MoyskladID}`, 
            search: `?city=${selectedCity}`
          }}
            state={{ product }} // Pass the product data as state
            className="card__img mb-[16px] sm:mb-[26px] flex flex-row justify-center">
          <img className="w-full h-full object-contain" src={imageURL} alt={product.Name} />
        </Link>
      </div>
      {/* price and colors */}
      <div className="flex flex-row justify-between w-full items-center">
        <div className="card__price"> {product.Price} Р</div>
        <div className="card__stock">*{product.Stock} шт. в наличии</div>
      </div>
      {/* card footer */}
    </div>
  );
}

export default ProductCard;
