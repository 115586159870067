import React from 'react';

const SquareCheckbox = ({ label, value, checked, onChange }) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        className="sr-only peer"
      />
      <div
        className={`w-[20px] h-[20px] border border-gray-300 rounded-md flex items-center justify-center 
          ${checked ? 'border-gray-500' : 'bg-white'}
        `}
      >
        {checked && <span className="text-[15px] text-gray-500">V</span>}
      </div>
      <span className="text-gray-800">{label}</span>
    </label>
  );
};

export default SquareCheckbox;
