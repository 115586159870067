// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { FavoritesProvider } from './contexts/FavoritesContext';
import { CartProvider } from './contexts/CartContext';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './index.css'; // Or './styles/tailwind.css' depending on where your Tailwind file is located


const root = createRoot(document.getElementById('root'));
root.render(
  <FavoritesProvider>
  <CartProvider>
    <Router>
      <App />
    </Router>
    </CartProvider>
  </FavoritesProvider>,
);
