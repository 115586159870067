import React from 'react';
import { Link } from 'react-router-dom';
import BaseLayout from './BaseLayout';
import tablesImg from '../assets/images/tables.jpg';
import tablesMobile from '../assets/images/table.jpg';
import chairsImg from '../assets/images/chairs.jpg';
import chairsMobile from '../assets/images/chair.jpg';
import sofasImg from '../assets/images/sofas.jpg';
import sofasMobile from '../assets/images/sofa.jpg';
import armchairsImg from '../assets/images/armchairs.jpg';
import armchairsMobile from '../assets/images/armchair.jpg';
import coffeeTablesImg from '../assets/images/coffee_tables.jpg';
import coffeeTablesMobile from '../assets/images/coffee_table.jpg';
import furnishingsImg from '../assets/images/furnishings.jpg';
import furnishingsMobile from '../assets/images/furnishing.jpg';
import Nav from './Nav.js';


const Catalog = () => {
    const navigationItems = {
        '': 'Главная', 
        'catalog': 'Каталог', 
    };

    const catalog = "Каталог";

  return (
    <BaseLayout>
        <main className="font-montserrat-regular text-default padding-default sm:m-0">
            {/* aside nav */}
            <Nav items={navigationItems} />
            {/*  h for pages */}
            <h2 className="h-default pb-[34px] sm:pb-[40px]">
                    {catalog}
            </h2>
            <div className="flex flex-row flex-wrap catalog__text justify-between gap-5 h-auto pb-[20px]
                    sm:pb-[60px] sm:justify-normal">
                <div className="flex flex-row gap-[30px] sm:gap-[20px]">
                    {/* tables */}
                    <Link to="/catalog/tables"
                          className="p-[20px] sm:p-0 border-default flex flex-col justify-between w-[160px] h-[260px] 
                            sm:gap-5 sm:h-auto sm:justify-normal
                            sm:w-[50%] sm:border-none">
                        <img className="sm:hidden" src={tablesMobile} alt="столы" />
                        <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                            src={tablesImg} alt="Столы"/>
                        <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                            Столы
                        </p>
                    </Link>
                    {/* chairs */}
                    <Link to="/catalog/chairs"
                          className="p-[20px] sm:p-0 border-default flex flex-col justify-between w-[160px] h-[260px] 
                            sm:gap-5 sm:h-auto sm:justify-normal
                            sm:w-[50%] sm:border-none">
                        <img className="sm:hidden" src={chairsMobile} alt="стулья"/>
                        <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                            src={chairsImg} alt="Стулья" />
                        <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                            Стулья
                        </p>
                    </Link>
      </div>
      <div className="flex flex-row flex-wrap gap-5 sm:gap-[20px]">
        <div className="flex flex-1 flex-row gap-[30px] sm:justify-between">
                {/* sofas */}
                <Link to="/catalog/sofas"
                      className="p-[20px] flex flex-col  w-[160px] border-default justify-between h-[260px]
                        sm:gap-5 sm:h-auto sm:p-0 sm:w-[48%] sm:border-none sm:justify-normal">
                    <img className="sm:hidden" src={sofasMobile} alt="диваны"/>
                    <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                        src={sofasImg} alt="Диваны"/>
                    <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                        Диваны
                    </p>
                </Link>
                {/* armchairs */}
                <Link to="/catalog/armchairs"
                      className="p-[20px] flex flex-col  w-[160px] border-default justify-between h-[260px]
                        sm:gap-5 sm:h-auto sm:p-0 sm:w-[48%] sm:border-none sm:justify-normal">
                    <img className="sm:hidden" src={armchairsMobile} alt="кресла"/>
                    <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                        src={armchairsImg} alt="Кресла"/>
                    <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                        Кресла
                    </p>
                </Link>
      </div>
      <div className="flex flex-1 flex-row gap-[30px] sm:justify-between">
                {/* coffee_tables */}
                <Link to="/catalog/coffee_tables"
                      className="p-[20px] flex flex-col  w-[160px] border-default justify-between h-[280px]
                        sm:gap-5 sm:h-auto sm:p-0 sm:w-[48%] sm:border-none sm:justify-normal">
                    <img className="sm:hidden" src={coffeeTablesMobile} alt="журнальные столики" />
                    <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                        src={coffeeTablesImg} alt="Журнальные столики" />
                    <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                        Журнальные столики
                    </p>
                </Link>
                {/* furnishings */}
                <Link to="/catalog/furnishings"
                      className="p-[20px] flex flex-col  w-[160px] border-default justify-between h-[280px]
                        sm:gap-5 sm:h-auto sm:p-0 sm:w-[48%] sm:border-none sm:justify-normal">
                    <img className="sm:hidden" src={furnishingsMobile} alt="предметы интерьера"/>
                    <img className="hidden sm:block sm:bg-cover sm:h-[90%]"
                        src={furnishingsImg} alt="Предметы интерьера"/>
                    <p className="btn-default w-[116px] p-2 sm:border-none sm:bg-white sm:pl-0 sm:text-left sm:w-auto">
                        Предметы интерьера
                    </p>
                </Link>
      </div>
      </div>
            </div>
        </main>
    </BaseLayout>
  );
};

export default Catalog;
