// src/components/Contacts.js
import React from 'react';
import BaseLayout from './BaseLayout';
import MapComponent from './Map';
import Nav from './Nav.js';


const Contacts = () => {
    const navigationItems = {
        '': 'Главная', 
        'contacts': 'Контакты', 
    };
    const contacts = "Контакты"

  return (
    <BaseLayout>
      <main className="font-montserrat-regular padding-default">
        {/* aside nav */}
        <Nav items={navigationItems} />
            {/* h for pages */}
            <h2 className="h2__aside mb-[36px] sm:mb-0">
              {contacts}
            </h2>
            <MapComponent />
      </main>
    </BaseLayout>
  );
};

export default Contacts;
