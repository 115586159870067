import React, { useState } from 'react';

function SortDropdown({ onSortChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Популярные");

  // Define sorting options
  const options = [
    { value: "popular", label: "Популярные" },
    { value: "newest", label: "Новинки" },
    { value: "priceAsc", label: "По возрастанию цены" },
    { value: "priceDesc", label: "По убыванию цены" },
    { value: "discountAsc", label: "По размеру скидки" },
  ];

  const handleSelect = (value, label) => {
    setSelectedOption(label);
    onSortChange(value);
    setIsOpen(false);
  };

  return (
    <div className="w-[210px] relative py-[1px]">
      {/* Button to display selected sorting option */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="sortSelect  flex justify-between items-center w-full"
      >
        <span>{selectedOption}</span>
        <span className="ml-2">&#x25BC;</span>
      </button>

      {/* Dropdown options */}
      {isOpen && (
        <ul className="bg-white absolute sortSelect__ul">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleSelect(option.value, option.label)}
              className="sortSelect__li text-black"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SortDropdown;

// // SortDropdown.js
// import React, { useState } from 'react';
//
// function SortDropdown({ onSortChange }) {
//   return (
//     <div className="sort-dropdown">
//       <select id="sort" onChange={(e) => onSortChange(e.target.value)}>
//         <option value="">Select an option</option>
//         <option value="popular">Popular</option>
//         <option value="newest">Novelties</option>
//         <option value="priceAsc">Price: Low to High</option>
//         <option value="priceDesc">Price: High to Low</option>
//         <option value="discountAsc">Discount: Low to High</option>
//       </select>
//     </div>
//   );
// }
//
// export default SortDropdown;
//
