// SquareRadioButton.js
import React from 'react';

const SquareRadioButton = ({ label, value, checked, onChange }) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      {/* Hidden native radio input */}
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="sr-only peer"
      />

      {/* Custom square to represent the radio button */}
      <div
        className={`w-[20px] h-[20px] border border-gray-300 rounded-md flex items-center justify-center
          ${checked ? 'border-gray-500' : 'bg-white'}
        `}
      >
        {/* Check mark */}
        {checked && (
          <span className="text-[15px] text-gray-500">V</span>
        )}
      </div>

      {/* Label next to the radio button */}
      <span className="text-gray-800">{label}</span>
    </label>
  );
};

export default SquareRadioButton;
