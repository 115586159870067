import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import BaseLayout from "./BaseLayout";
import CartButton from "./CartButton";
import FavoriteButton from "./FavoriteButton";
import Popup from "./Popup";
import ProductImageSlider from "./ProductImageSlider.js";

const ExtendedProductCard = ({ product }) => {
  const { category } = useParams();
  const location = useLocation();

  const initialProduct = useMemo(() => {
    return location.state?.product || product;
  }, [location.state?.product, product]);

  // Initialize selectedModification and currentImages without conditional hook calls
  const [selectedModification, setSelectedModification] = useState(
    initialProduct.Modification?.[0] || {}
  );

  const [currentImages, setCurrentImages] = useState(
    selectedModification?.ModificationImages?.length
      ? selectedModification.ModificationImages
      : initialProduct.ProductImages || [] // Ensure currentImages has a default array
  );

  useEffect(() => {
    setCurrentImages(
      selectedModification?.ModificationImages?.length
        ? selectedModification.ModificationImages
        : initialProduct.ProductImages || []
    );
  }, [selectedModification, initialProduct]);

  const handleModificationClick = (modification) => {
    setSelectedModification(modification);
  };

  // If there's no initial product, display a "Product not found" message in the render
  return (
    <BaseLayout>
      <main className="font-montserrat-regular px-20 pb-20 text-default">
        {/* Navigation breadcrumb */}
        <aside className="aside-default">
          <Link to="/">Главная / </Link>
          <Link to="/catalog">Каталог / </Link>
          <Link to={`/catalog/${category}`}>
            {initialProduct.Category?.Name || "Категория"} /{" "}
          </Link>
          <span>{initialProduct.Name || "Product not found"}</span>
        </aside>

        {initialProduct.Name ? (
          <div className="flex flex-col border-default sm:gap-3 sm:border-none sm:flex-row">
            {/* Product Card Image and Details */}
            <div className="card-page sm:border-default">
              <div className="mb-[24px] sm:mb-[10px] flex flex-row justify-between sm:justify-end">
                <div className="text-center sm:hidden">{initialProduct.Name}</div>
                <FavoriteButton product={initialProduct} />
              </div>

              <div className="items-center h-full">
                {/* Only render ProductImageSlider if there are images */}
                {currentImages.length > 0 ? (
                  <ProductImageSlider
                    initialProduct={initialProduct}
                    selectedModification={selectedModification}
                  />
                ) : (
                  <p>No images available</p>
                )}
              </div>
            </div>

            {/* Modification Details */}
            <div className="card-page sm:border-default">
              <button
                className="hidden text-left text-[20px] md:text-[22px] lg:text-[24px] sm:block"
              >
                {initialProduct.Name}
              </button>

              {/* Mini images for modifications */}
              {initialProduct.Modification?.length > 0 && (
                <div className="flex flex-row flex-wrap gap-[20px]">
                  {initialProduct.Modification.map((modification, index) => (
                    <img
                      key={index}
                      className="w-[75px] h-[70px] cursor-pointer"
                      src={`${process.env.REACT_APP_API_URL}/${modification.ModificationImages?.[0]?.ImageURL || ''}`}
                      alt={`Mini ${index + 1}`}
                      onClick={() => handleModificationClick(modification)}
                    />
                  ))}
                </div>
              )}

              {/* Price and Stock for the selected modification */}
              <div className="sm:flex-row flex flex-col justify-between">
                <div className="text-[20px] md:text-[22px] lg:text-[24px]">
                  {selectedModification?.SalePrices || initialProduct?.Price} Р
                </div>
                <div className="text-[20px] md:text-[22px] lg:text-[24px]">
                  {selectedModification?.Stock || initialProduct?.Stock} шт. в наличии
                </div>
              </div>

              {/* Add To Cart */}
              <CartButton
                product={initialProduct}
                isOutOfStock={initialProduct.Stock === 0}
              />

              {/* Characteristics for the selected modification */}
              <div className="text-[14px] sm:text-[18px] flex flex-col gap-5 sm:gap-[24px]">
                {selectedModification?.ModificationCharacteristics?.length > 0 ? (
                  selectedModification.ModificationCharacteristics.map(
                    (characteristic, index) => (
                      <div className="flex flex-row" key={index}>
                        <span className="w-[150px] font-montserrat-semibold">
                          {characteristic.Name}
                        </span>
                        <span>{characteristic.Value}</span>
                      </div>
                    )
                  )
                ) : (
                  <p>No characteristics available</p>
                )}
                <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid">
                  Товар доступен для заказа в других цветах и материалах
                </p>
                <Popup
                  buttonText="Индивидуальный заказ"
                  className="btn-mod border-mod"
                />
                <p className="pt-[20px] border-[#A3ACA3] border-t-[1px] border-solid text-[10px] sm:text-[12px]">
                  * Окончательная стоимость доставки рассчитывается индивидуально,
                  в зависимости от удаленности
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>Product not found.</div>
        )}
      </main>
    </BaseLayout>
  );
};

export default ExtendedProductCard;

