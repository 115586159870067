// src/components/DeliveryPayment.js
import React from 'react';
import BaseLayout from './BaseLayout';
import sideBack from '../assets/images/side_first.jpg';
import sideMobile from '../assets/images/side_back.png';
import Popup from './Popup';
import Nav from './Nav.js';


const DeliveryPayment = () => {
    const navigationItems = {
        '': 'Главная', 
        'delivery_payment': 'Доставка и оплата', 
    };

  return (
    <BaseLayout>
<main className="font-montserrat-regular padding-default">
        {/* aside nav */}
      <Nav items={navigationItems} />
                <div className="
                    rounded border-0 border-solid color-footer flex flex-col pb-20 bg-right-bottom
                    sm:pb-0 sm:bg-none sm:flex-row"
                    style={{ backgroundImage: `url(${sideMobile})`}}>
                    <div className="
                        flex-1 text-14 pr-10 bg-back
                        md:text-16 md:pr-12
                        lg:text-18 lg:pr-20
                        xl:text-18 xl:pr-36">
                        {/* h for pages */}
                        <h2 className="h2__aside pb-[10px] sm:pb-[50px]">
                          Доставка и оплата
                        </h2>
                        <div className="pb-[40px]">
                           <p>Доставка:</p>
                            <p>
                                - Мы осуществляем доставку нашей продукции во все регионы и города.
                            </p>
                            <p>
                                - Стоимость доставки рассчитывается в зависимости от веса и размеров товара, а также вашего местоположения.
                            </p>
                            <p>
                                - Мы сотрудничаем с надежными службами доставки, чтобы уверенно доставить ваш заказ вовремя и в отличном состоянии.
                            </p>
                            <p>
                                - Время доставки может варьироваться в зависимости от вашего местоположения. Мы стараемся доставить заказ как можно скорее.
                            </p>
                        </div>
                      <Popup buttonText="Заказать обратный звонок"
                            className="bttn py-2.5 px-5
                                       sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9" />
                    </div>
                    <div className="
                        hidden flex-1 p-0
                        sm:flex ">
                        <img className="w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]" src={ sideBack }
                             alt="Доставка и оплата" />
                    </div>
                </div>
            </main>
    </BaseLayout>
  );
};

export default DeliveryPayment;
