// src/components/Privacy.js
import React from 'react';
import BaseLayout from './BaseLayout';


const Privacy = () => {
  return (
    <BaseLayout>
      <p>Privacy</p>
    </BaseLayout>
  );
};

export default Privacy;
