// src/contexts/CityContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context
const CityContext = createContext();

// Create a provider component
export const CityProvider = ({ children }) => {
  const [selectedCity, setSelectedCity] = useState('moscow'); // Default city

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  return (
    <CityContext.Provider value={{ selectedCity, handleCityChange }}>
      {children}
    </CityContext.Provider>
  );
};

// Create a custom hook to use the CityContext
export const useCity = () => {
  return useContext(CityContext);
};
