// src/components/CitySelection.js
import React, { useState } from 'react';
import { useCity } from '../contexts/CityContext'; // Import the custom hook

const CitySelection = () => {
  const { selectedCity, handleCityChange } = useCity(); // Use context
  const [isOpen, setIsOpen] = useState(false);

  // Define the cities
  const cities = [
    { value: 'moscow', label: 'Москва' },
    { value: 'saratov', label: 'Саратов' },
  ];

//
const filteredCities = cities.filter(city => city.value !== selectedCity);

  // Handle city selection
  const handleSelect = (city) => {
    handleCityChange(city); // Pass selected city to context
    setIsOpen(false); // Close dropdown
  };

  return (
    <div className="py-[1px] ">
      {/* Button to display selected city */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="citySelect"
      >
        {cities.find(city => city.value === selectedCity)?.label || "Select City"} &#x25BC;
      </button>

      {/* Dropdown options */}
      {isOpen && (
        <ul className="sm:absolute text-center citySelect__ul">
          {filteredCities.map((city) => (
            <li
              key={city.value}
              onClick={() => handleSelect(city.value)}
              className="citySelect__li"
            >
              {city.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CitySelection;

