import React, { useContext, useState, useEffect } from 'react';
import CartContext from '../contexts/CartContext';

const CartButton = ({ product, isOutOfStock }) => {
  const { cart, addToCart, removeFromCart, updateCartItemQuantity } = useContext(CartContext);

  // Set quantity state
  const [quantity, setQuantity] = useState(0);

  // Update quantity when product changes
  useEffect(() => {
    const cartItem = cart.find((item) => item.MoyskladID === product.MoyskladID);
    setQuantity(cartItem ? cartItem.quantity : 0);
  }, [product, cart]);

  // Handle adding the product to the cart
  const handleAddToCart = () => {
    if (quantity === 0) {
      addToCart(product);
      setQuantity(1);
    }
  };

  // Handle incrementing the quantity
  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    updateCartItemQuantity(product.MoyskladID, newQuantity);
  };

  // Handle decrementing the quantity
  const handleDecrement = () => {
    const newQuantity = quantity - 1;

    if (newQuantity > 0) {
      setQuantity(newQuantity);
      updateCartItemQuantity(product.MoyskladID, newQuantity);
    } else {
      removeFromCart(product.MoyskladID);
      setQuantity(0);
    }
  };

  return (
    <>
      {isOutOfStock ? (
        <span className="text-[14px] sm:text-[18px] text-red-500">Товар временно отсутствует.</span>
      ) : quantity === 0 ? (
        <button onClick={handleAddToCart} className="bttn w-full sm:px-[20px] sm:w-[230px]">
          Добавить в корзину
        </button>
      ) : (
        <div className="w-[80px] sm:px-[10px] border-[1px] rounded-[5px] flex items-center border-[#A3ACA3] border-solid">
          <button onClick={handleDecrement} className="btn-decrement">-</button>
          <span className="px-4">{quantity}</span>
          <button onClick={handleIncrement} className="btn-increment">+</button>
        </div>
      )}
    </>
  );
};

export default CartButton;
