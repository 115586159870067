// src/utils/localStorage.js
const CART_KEY = 'cart';

export const loadFavorites = () => {
  try {
    return JSON.parse(localStorage.getItem('favorites')) || [];
  } catch (e) {
    console.error("Error loading favorites from localStorage", e);
    return [];
  }
};

export const saveFavorites = (favorites) => {
  try {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  } catch (e) {
    console.error("Error saving favorites to localStorage", e);
  }
};

// Check if a product is in favorites
export const isFavorite = (MoyskladID) => {
  const favorites = loadFavorites();
  return favorites.some((item) => item.MoyskladID === MoyskladID);
};

// --------- Cart Management ---------
// Save the cart to localStorage
export const saveCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

// Load the cart from localStorage
export const loadCart = () => {
  const data = localStorage.getItem(CART_KEY);
  return data ? JSON.parse(data) : [];
};

// Clear the cart from localStorage (optional)
export const clearCart = () => {
  localStorage.removeItem(CART_KEY);
};
