import React, { useState, useEffect } from "react";
import LeftArrowIcon from "../assets/images/left-arrow.svg";
import RightArrowIcon from "../assets/images/right-arrow.svg";

function ProductImageSlider({ initialProduct, selectedModification }) {
  // Determine the images to show based on the selected modification
  const images = selectedModification?.ModificationImages?.length
    ? selectedModification.ModificationImages
    : initialProduct.ProductImages;

  // State to keep track of the currently displayed image
  const [currentIndex, setCurrentIndex] = useState(0);

  // Update currentIndex if the images change due to modification change
  useEffect(() => {
    setCurrentIndex(0);
  }, [images]);

  // Handlers for navigating images
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Handler for clicking a thumbnail
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="product-image-slider">
      {/* Main Image Display with Custom SVG Arrows */}
      <div className="relative flex items-center justify-center">
        {/* Left Arrow with SVG */}
        <button
          onClick={handlePrev}
          className="absolute left-0"
        >
          <img className="w-[24px] h-[24px] sm:w-[50px] sm:h-[50px]" src={LeftArrowIcon} alt="LeftArrow" />
        </button>

        {/* Current Main Image */}
        <div className="w-[310px] h-[300px]
                      lg:h-[500px] lg:w-[416px]">
          <img
            className="object-contain w-full h-full"
            src={`${process.env.REACT_APP_API_URL}/${images[currentIndex].ImageURL}`}
            alt={`${initialProduct.Name} - Image ${currentIndex + 1}`}
          />
        </div>

        {/* Right Arrow with SVG */}
        <button
          onClick={handleNext}
          className="absolute right-0"
        >
          <img className="w-[24px] h-[24px] sm:w-[50px] sm:h-[50px]"  src={RightArrowIcon} alt="RightArrow" />
        </button>
      </div>

      {/* Thumbnail Images */}
      <div className="flex justify-center mt-4 space-x-2">
        {images.map((image, index) => (
          <img
            key={index}
            className={`w-[75px] h-[70px] object-cover cursor-pointer border-2 ${
              currentIndex === index ? "border-gray-900" : "border-transparent"
            }`}
            src={`${process.env.REACT_APP_API_URL}/${image.ImageURL}`}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductImageSlider;
